import React, {useEffect, useState} from "react";
import Modal from "../../../components/My/Modal";
import {Button} from "../../../components/My/Button";
import {apiGetListOrders, apiUpdateBankTransaction} from "../Actions";
import Toast from "cogo-toast";
import Table from "../Order/Table";
import {Form} from "react-final-form";
import SelectField from "../../../FinalForm/SelectField";
import SubjectField from "../../../FinalForm/api/SubjectField";


const ModalDetailPayment = ({visible, close}) => {

    let [loading, setLoading] = useState(false)
    let [listOrder, setListOrder] = useState(null)

    useEffect(() => {
        visible && visible.status !== 2 && getOrders()
    }, [visible])

    const getOrders = () => {
        setLoading(true)
        let filters = {status: 0, price: visible.price}
        apiGetListOrders(filters, (data) => {
            setListOrder(data)
            setLoading(false)
        }, err => {
            setLoading(false)
            Toast.warn("Došlo k chybě.")
        })
    }

    const save = (values) => {
        values.bankTransactionId = visible.id;

        apiUpdateBankTransaction(values, (data) => {
            Toast.success("Dokončeno")
            close(true)
        }, error => {
            Toast.warn("Došlo k chybě.")
        })
    }

    const getList = () => {
        if (loading) return <div className={"font-bold"}>Načítám..</div>
        if (visible.status === 2) return <div className={"font-bold"}>Objednávka úspěšně dokončena.</div>
        if (!listOrder || listOrder.length === 0) {
            return (
                <div className={"font-bold"}>Žádné objednávky, které by odpovídaly částce.</div>
            )
        }
        return (
            <div className={"flex flex-col"}>
                <h5 className={"font-bold"}>Podobné objednávky</h5>
                <div className={"w-full overflow-auto"}>
                    <Table data={listOrder}/>
                </div>
            </div>
        )
    }



    const getForm = () => {
        return (
            <Form onSubmit={save} initialValues={{subjectId: visible.subject.id, status: visible.status}}
                  render={({handleSubmit}) => {
                      return (
                          <div className={"flex flex-col"}>
                              <SelectField name={"status"} options={[{label: "Došlo k chybě", value: 1}, {
                                  label: "Dokončeno",
                                  value: 2
                              }]} label={"Status"}
                                           isMandatory={true}/>
                              <SubjectField name={"subjectId"} label={"Subjekt"} isMandatory={true}/>
                              <Button outline={true} text={"Uložit změny"} onClick={handleSubmit}/>
                          </div>
                      )
                  }}/>
        )
    }

    const getBody = () => {
        let row = visible.data
        let array = []
        /*Object.keys(row).map(i => {
            if (row[i]?.name) {
                array.push(<div><b>{row[i]?.name}:</b> {row[i]?.value}</div>)
            }
        })*/
        return (
            <div className={"flex flex-col"}>
                <div className={"flex flex-row"}>
                    <div className={"w-1/2 h-64 overflow-auto"}>
                        <h6 className={"font-bold"}>Informace z banky</h6>
                        <pre>
                            {JSON.stringify(visible.data, null, 2)}
                        </pre>
                    </div>
                    <div className={"w-1/2"}>
                        {visible && visible.status !== 2 &&
                            <>
                                <h6 className={"font-bold"}>Úprava dat</h6>
                                {getForm()}
                            </>
                        }
                    </div>

                </div>
                <div className={"flex h-64"}>
                    {getList()}
                </div>
            </div>
        )
    }

    if (!visible) return null
    return (
        <>
            <Modal body={getBody()} title={"Informace o transakci"} visible={visible} close={() => close(null)}
                   footer={<Button outline={true} text={"Zavřít"} onClick={() => close()}/>}
            />
        </>
    )

}

export default ModalDetailPayment
